import { protectedPaths, secure } from '../data/DataManagement';

export const fetchAllData = () => {

  const isProductionEnv = true;

  return new Promise(async (resolve, reject) => {
    try {
      
      //const dataFileOnServer = protectedPaths.protectedAllDataUrl;
      var dataFileOnServer;

      if (isProductionEnv) {
         dataFileOnServer = "https://serenity-waves.com/protected/data/DATA_All.json";
      } else {
         dataFileOnServer = process.env.REACT_APP_NODEJS_CORS_PROXY_DATA_URL;
      }

      
      const response = await fetch(dataFileOnServer,{
        // works in Dev
        //   method: 'POST',
       
        //Works in Prod
        method: 'GET',
        
        headers: new Headers({
          'Authorization': secure.authorization,
          'Content-Type': 'application/json'
        })
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();        
      console.log("ALLData Fetched", data);
      if(data != null) {
        resolve(data)
      } else {
        reject(null);
      };
      
    } catch (error) {
      reject(error);
    }
  });
};