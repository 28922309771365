const getActiveCategories = (categories) => {
    const filteredActiveCategories = categories.filter(filteredObject => filteredObject["Active"] == true);
    return filteredActiveCategories;
}
const getNarratedContentCategories = (categories) => {
    const filteredNarratedContentCategories = categories.filter(filteredObject => filteredObject["isNarratedContent"] == true);
    return filteredNarratedContentCategories;
}
const getMusicCategories = (categories) => {
    const filteredMusicCategories = categories.filter(filteredObject => filteredObject["isMusic"] == true);
    return filteredMusicCategories;
}

const getFilteredContentByCategory = (categoryObject,categorizedContent) => {

    var filterResults;

    var filteredCategorizedContent = categorizedContent.filter(filteredObject => filteredObject["Category-Name"] == categoryObject["Category-Name"]);
    console.log("Category Filtered Content", filteredCategorizedContent);
  
    filterResults = filteredCategorizedContent;
  
    if (categoryObject.subcategory !== "") {
      var subFilteredCategorizedContent = filterResults.filter(filteredObject => filteredObject["Subcategory-Name"] == categoryObject["Subcategory-Name"]);
      console.log("SubCateogry Filtered Content", subFilteredCategorizedContent);
      filterResults = subFilteredCategorizedContent;
     }
  
     return filterResults;

}

const getFilteredContentByLanguage = (language, filteredContent) => {

    var languageFilteredContent = filteredContent.filter(filteredObject => filteredObject["Language"] == language["Language-Code"]);
    console.log("DataFilters -> getFilteredContentByLanguage -> languageFilteredContent: " , languageFilteredContent);

     return languageFilteredContent;

}

const getFilteredContentByGender = (gender,filteredContent) => {

    var genderFilteredContent = filteredContent.filter(filteredObject => filteredObject["Gender"] == gender);
    console.log("DataFilters -> getFilteredContentByGender -> genderFilteredContent: " , genderFilteredContent);

     return genderFilteredContent;
}


const getFilteredMusicAlbumsByCategory = (categoryName,contentToFilter) => {

    var filteredContent;

    switch (categoryName) {

        case "Ambient":
            {
                filteredContent = contentToFilter.filter(filteredObject => filteredObject["isAmbient"] == true);
            }
            break;
        case "Meditation":
          {
                filteredContent = contentToFilter.filter(filteredObject => filteredObject["isMeditation"] == true);                
            }
        break;

        case "Sleep":
            {
                filteredContent = contentToFilter.filter(filteredObject => filteredObject["isSleep"] == true);                                
            }
        break;

        case "Instrumental":
            {
                filteredContent = contentToFilter.filter(filteredObject => filteredObject["isInstrumentalNature"] == true);                
            }
        break;

        case "Natural Ambient":
            {
                filteredContent = contentToFilter.filter(filteredObject => filteredObject["isNaturalAmbient"] == true);                                
            }
        break;

           
        default:
            {
                filteredContent = contentToFilter;
            }
            break;
        }

        return filteredContent;
}


const getAvailableLanguagesByCategory = (filteredContent,languages) => {

    // var audioData = JSON.parse(filterResults);
    var uniqueLanguageCodes = new Set();

    // Iterate over the audioData and add unique language codes to the set
    filteredContent.forEach(function(audio) {
      uniqueLanguageCodes.add(audio["Language"]);
    });

    // Convert the Set back to an array if needed
    var uniqueLanguageCodesArray = Array.from(uniqueLanguageCodes);

    console.log("uniqueLanguageCodesArray" , uniqueLanguageCodesArray);        

    // Filter JSON data based on language codes
    var availableLanguages = languages.filter(function(obj) {
        // Check if the "Language" attribute of the object is in the languageCodes array
        return uniqueLanguageCodesArray.includes(obj["Language-Code"]);
    });

    console.log("DataFilter -> getAvailableLanguagesByCategory" , availableLanguages ); 
    
    return availableLanguages;
}

const getAvailableGendersByCategory = (filteredContent) => {

    var uniqueGenders = new Set();

    // Iterate over the audioData and add unique language codes to the set
    filteredContent.forEach(function(audio) {
        uniqueGenders.add(audio["Gender"]);
    });

    // Convert the Set back to an array if needed
    var uniqueGendersArray = Array.from(uniqueGenders);

    console.log("DataFilter -> getAvailableGendersByCategory -> uniqueGendersArray" , uniqueGendersArray ); 

    const genders = ['MA','FE'];

    var availableGenders = genders.filter(function(obj) {
        // Check if the "Language" attribute of the object is in the languageCodes array
        return uniqueGendersArray.includes(obj);
    });

    console.log("DataFilter -> getAvailableGendersByCategory -> availableGenders" , availableGenders ); 


    return availableGenders;
}

export { getFilteredContentByCategory,
         getFilteredContentByLanguage,
         getFilteredContentByGender,
         getAvailableLanguagesByCategory,
         getAvailableGendersByCategory,
         getActiveCategories,
         getNarratedContentCategories,
         getMusicCategories,
         getFilteredMusicAlbumsByCategory};
